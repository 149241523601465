<template>
  <div class="container">
    <div class="pagemain">
      <div class="Qamain">
        <div class="Qaicon icon1">Q</div>
        <div class="Qatitle">{{ ti.ti1 }}.请问您需要哪方面的帮助？</div>
        <div class="Qaselect">
          <van-field
            readonly
            class="helpinput"
            clickable
            :value="help"
            placeholder="请选择"
            @click="showhelp = true"
          />
          <img class="down" src="@/assets/images/red_pull_icon@2x.png" />
          <van-popup v-model="showhelp" round position="bottom">
            <van-picker
              title=""
              show-toolbar
              :columns="helpList"
              @cancel="showhelp = false"
              @confirm="changehelp"
            />
          </van-popup>
        </div>
      </div>

      <div
        class="Qamain"
        v-if="form.Help == '产品售后'"
      >
        <div class="Qaicon icon2">Q</div>
        <div class="Qatitle">{{ ti.ti4 }}.您的问题涉及的相关产品是？</div>
        <div class="Qaselect">
          <van-field
            readonly
            class="aboutinput"
            clickable
            :value="about"
            placeholder="请选择"
            @click="showabout = true"
          />
          <img class="down" src="@/assets/images/purple_pull_icon@2x.png" />
          <van-popup v-model="showabout" round position="bottom">
            <van-picker
              title=""
              show-toolbar
              :columns="aboutList"
              @cancel="showabout = false"
              @confirm="changeabout"
            />
          </van-popup>
        </div>
      </div>

      <div
        class="Qamain"
        v-if="form.Help == '新产品试用'"
      >
        <div class="Qaicon icon2">Q</div>
        <div class="Qatitle">{{ ti.ti4 }}.您想缝合的层次是什么？</div>
        <div class="Qaselect">
          <van-field
            v-if="this.userInfo.Type == 1"
            readonly
            class="aboutinput"
            clickable
            :value="suture"
            placeholder="请选择"
            @click="sutureShow = true"
          />
          <input v-else class="sutureInput" type="text" v-model="suture" placeholder="请输入" />
          <img class="down" src="@/assets/images/purple_pull_icon@2x.png" />
          <van-popup v-model="sutureShow" round position="bottom">
            <van-picker
              title=""
              show-toolbar
              :columns="sutureList"
              value-key="Content"
              @cancel="sutureShow = false"
              @confirm="changeSuture"
            />
          </van-popup>
        </div>
      </div>

      <div
        class="Qamain"
        v-if="form.Help == '新产品试用'"
      >
        <div class="Qaicon icon2">Q</div>
        <div class="Qatitle">{{ ti.ti5 }}.您想试用的产品是？</div>
        <div class="Qaselect">
          <van-field
            readonly
            class="aboutinput"
            clickable
            :value="product"
            placeholder="请选择"
            @click="productShow = true"
          />
          <img class="down" src="@/assets/images/purple_pull_icon@2x.png" />
          <van-popup v-model="productShow" round position="bottom">
            <van-picker
              title=""
              show-toolbar
              :columns="productList"
              @cancel="productShow = false"
              @confirm="changeProduct"
            />
          </van-popup>
        </div>
      </div>

      <div class="Qamain">
        <div class="Qaicon icon3">Q</div>
        <div class="Qatitle">{{ ti.ti2 }}.什么时候方便联系您？</div>
        <div class="Qaselect">
          <van-field
            readonly
            class="timeinput"
            clickable
            :value="time"
            placeholder="请选择"
            @click="showtime = true"
          />
          <img class="down" src="@/assets/images/blue_pull_icon@2x.png" />
          <van-popup v-model="showtime" round position="bottom">
            <van-picker
              title=""
              show-toolbar
              :columns="timeList"
              @cancel="showtime = false"
              @confirm="changetime"
            />
          </van-popup>
        </div>
      </div>

      <div class="Qamain" style="margin-bottom: 0">
        <div class="Qaicon icon4">Q</div>
        <div class="Qatitle">{{ ti.ti3 }}.是否可以通过注册手机号联系您？</div>
        <div class="btn">
          <van-button
            type="primary"
            plain
            class="yes"
            v-if="yesshow == true"
            @click="
              yesshow = false;
              noshow = true;
            "
            >是</van-button
          >
          <van-button
            type="primary"
            plain
            class="no"
            v-if="yesshow == false"
            @click="yesshow = true"
            >是</van-button
          >
        </div>
        <div class="btn">
          <van-button
            type="primary"
            plain
            class="yes"
            v-if="noshow == true"
            @click="
              noshow = false;
              yesshow = true;
            "
            >否</van-button
          >
          <van-button
            type="primary"
            plain
            class="no"
            v-if="noshow == false"
            @click="noshow = true"
            >否</van-button
          >
        </div>
        <div class="btn" v-if="noshow == false">
          <van-field
            class="phoneinput"
            v-model="form.Phone"
            placeholder="请输入您的手机号"
          />
        </div>
      </div>
    </div>
    <div class="declare" @click="checkedDeclare">
      <img :src="isCheckedDeclare ? require('@/assets/images/system-complete@2x.png') : require('@/assets/images/dis_system_complete_icon@2x.png')"/>
      <span>若您希望进一步参与与本平台直播内容相关的讨论及互动，请您提供以上个人信息字段。强生承诺仅为上述目的收集和处理您提供的以上个人信息字段并将按照相关法律法规的要求保护您的个人信息。您已明确知晓和同意向强生提供和由强生处理以上个人信息。您同意仅提供本人信息，不会提供他人的个人信息。</span>
    </div>
    <div class="upload">
      <van-button plain type="primary" class="back" @click="back"
        >返回</van-button
      >
      <van-button type="danger" class="up" @click="upload">提交</van-button>
    </div>
  </div>
</template>

<script>
import utils from "../../common/utils/utils";
import { mapState } from "vuex";
import { Dialog } from "vant";
export default {
  name: "ContactUs",
  data() {
    return {
      form: {
        Help: "",
        Intend: "",
        Product: "",
        Time: "",
        Phone: "",
      },
      yesshow: true,
      noshow: true,
      help: "",
      about: "",
      time: "",
      helpList: ["产品售后", "新产品试用", "外科技能操作练习", "其他"],
      timeList: [
        "周一 上午",
        "周一 下午",
        "周二 上午",
        "周二 下午",
        "周三 上午",
        "周三 下午",
        "周四 上午",
        "周四 下午",
        "周五 上午",
        "周五 下午",
      ],
      aboutList: [
        "可吸收单根针",
        "可吸收八根针",
        "防刺伤单根针",
        "防刺伤八根针",
        "免打结倒刺线",
        "血管缝线",
        "不可吸收缝线",
        "其他",
      ],
      showhelp: false,
      showabout: false,
      showtime: false,
      ti: {
        ti1: "1",
        ti2: "2",
        ti3: "3",
        ti4: "4",
        ti5: "5",
      },
      suture: "",
      sutureList: [],
      sutureShow: false,
      product: "",
      productList: [
        "薇乔",
        "快薇乔",
        "抗菌薇乔",
        "普迪思",
        "抗菌普迪思",
        "单乔",
        "普理灵",
        "免打结倒刺线",
        "鱼骨倒刺线",
        "多抹棒",
        "爱惜邦",
        "ZipFix",
        "其他"
      ],
      productShow: false,
      isCheckedDeclare: false
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  created() {
    this.getSuture();
  },
  methods: {
    getSuture() {
      this.$axios.post("/Api/Api/Web/ContactUs/GetArrangements?SubCode=" + this.userInfo.SubjectCode).then((res) => {
        this.sutureList = res.Data;
      });
    },
    changeProduct(val) {
      this.product = val;
      this.productShow = false;
      this.form.Intend = val;
    },
    changeSuture(val) {
      this.suture = val.Content;
      this.sutureShow = false;
      this.form.Product = val.Content;
    },
    changehelp(val, index) {
      this.help = val;
      this.showhelp = false;
      console.log("help：", val);
      this.form.Help = val;
      this.about = "";
      this.suture = "";
      this.form.Product = "";
      this.product = "";
      this.form.Intend = "";
      if (this.form.Help == "产品售后") {
        this.ti.ti2 = 3;
        this.ti.ti3 = 4;
        this.ti.ti4 = 2;
        this.ti.ti5 = 5;
      } else if (this.form.Help == "新产品试用") {
        this.ti.ti2 = 4;
        this.ti.ti3 = 5;
        this.ti.ti4 = 2;
        this.ti.ti5 = 3;
      } else {
        this.ti.ti2 = 2;
        this.ti.ti3 = 3;
        this.ti.ti4 = 4;
        this.ti.ti5 = 5;
      }
    },
    changeabout(val, index) {
      this.about = val;
      this.showabout = false;
      console.log("about：", val);
      this.form.Product = val;
    },
    changetime(val, index) {
      this.time = val;
      this.showtime = false;
      console.log("time：", val);
      this.form.Time = val;
    },
    checkedDeclare() {
      this.isCheckedDeclare = !this.isCheckedDeclare;
    },
    back() {
      this.$router.go(-1);
    },
    upload: utils.debounce(function() {
      let that = this;
      let regmob = /^1[0-9]{10}$/;
      this.form.Product = this.suture;
      // alert(this.noshow + "111" + this.yesshow);
      if (this.form.Help == "") {
        Dialog.alert({
          message: "请选择您需要哪方面的帮助",
        });
      } else if (this.form.Help == "产品售后" && this.form.Product == "") {
        Dialog.alert({
          message: "请选择您的问题涉及的相关产品",
        });
      } else if (this.form.Help == "新产品试用" && this.form.Intend == "") {
        Dialog.alert({
          message: "请选择您想试用的产品",
        });
      }  else if (this.form.Help == "新产品试用" && this.form.Product == "") {
        Dialog.alert({
          message: "请选择您想缝合的层次",
        });
      } else if (this.form.Time == "") {
        Dialog.alert({
          message: "请选择什么时候方便联系您",
        });
      } else if (this.noshow == true && this.yesshow == true) {
        Dialog.alert({
          message: "请选择我们是否可以通过注册手机号联系您",
        });
      } else if (this.noshow == false && this.form.Phone == "") {
        Dialog.alert({
          message: "请输入可联系您的手机号码",
        });
      } else if (this.noshow == false && !regmob.test(this.form.Phone)){
        Dialog.alert({
          message: "请输入正确的手机号码",
        });
      } else if (!this.isCheckedDeclare) {
        Dialog.alert({
          message: "请勾选协议",
        });
      }  else {
        that.$axios
          .post("/Api/Api/Web/ContactUs/AddContactUs", this.form)
          .then((res) => {
            if (res.RetCode == '10000') {
              this.$dialog.alert({
                message: "提交成功！",
              });
            } else {
              this.$toast(res.RetMsg);
            }
            
          });
      }
    }, 1000)
  },
};
</script>


<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  background: #f9f9f9;
  padding-top: 1em;
}

.pagemain {
  width: 82%;
  margin: auto;
  padding: 1em 0;
  padding-left: 1.5em;
  border-left: 1px dashed #a7a7a7;
}

.Qamain {
  background: #fff;
  padding: 1.5em 1em;
  width: 90%;
  text-align: center;
  border-bottom-left-radius: 1.5em;
  border-top-right-radius: 1.5em;
  border-bottom-right-radius: 1.5em;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 2em;
  position: relative;
}

.Qatitle {
  font-size: 15px;
  margin-bottom: 1em;
  text-align: left;
  padding-left: 16px;
}

.helpinput {
  border: 1px solid #f9a9dd;
  border-radius: 8px;
  padding: 0.5em 1em;
  font-size: 1em;
}

.aboutinput {
  border: 1px solid #ddb7f3;
  border-radius: 8px;
  padding: 0.5em 1em;
  font-size: 1em;
}

.sutureInput {
  width: 100%;
  height: 25px;
  border: 1px solid #ddb7f3;
  border-radius: 8px;
  padding: 0.5em 1em;
  font-size: 1em;
  color: #b53fcb;
  font-weight: bold;
}

.phoneinput {
  border: 1px solid #ddb7f3;
  border-radius: 8px;
  padding: 0.5em 1em;
  font-size: 1em;
  width: 100%;
  margin: auto;
}

.timeinput {
  border: 1px solid #720bfb;
  border-radius: 8px;
  padding: 0.5em 1em;
  font-size: 1em;
}

.Qaselect {
  position: relative;
  display: flex;
}

.down {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  width: 6%;
}

/deep/.helpinput > div > div > .van-field__control {
  color: #ff5896;
  font-weight: bold;
}

.icon1 {
  background: #ff5896 !important;
}

/deep/.aboutinput > div > div > .van-field__control {
  color: #b53fcb;
  font-weight: bold;
}

.icon2 {
  background: #b53fcb !important;
}

/deep/.timeinput > div > div > .van-field__control {
  color: #7d9ce3;
  font-weight: bold;
}

.icon3 {
  background: #7d9ce3 !important;
}

.yes {
  width: 100%;
  margin-bottom: 1.5em;
  color: #720bfb;
  border-color: #720bfb;
  border-radius: 5px;
}

.icon4 {
  background: #720bfb !important;
}

.no {
  width: 100%;
  margin-bottom: 1.5em;
  color: #fff;
  border-color: #720bfb;
  background: #720bfb;
  border-radius: 5px;
}

.Qaicon {
  position: absolute;
  left: -12.5%;
  top: 0;
  background: #fff;
  color: #fff;
  padding: 0.5em;
  border-radius: 5em;
  width: 1em;
  height: 1em;
  font-size: 15px;
  line-height: 0.9em;
}

.upload {
  width: 90%;
  margin: auto;
  margin-top: 1em;
  padding-bottom: 4em;
}

.back {
  margin-right: 1em;
  width: 47%;
  border-radius: 5px;
  color: red;
  border-color: red;
  background: #f9f9f9;
}

.up {
  color: #fff;
  width: 47%;
  border-radius: 5px;
}

.declare {
  display: flex;
  font-size: 11px;
  color: #666666;
  padding: 0 10px;
  margin-top: 15px;
  img {
    display: block;
    width: 14px;
    height: 14px;
    margin: 2px 5px 0 10px;
  }
}
/*webkit 内核浏览器*/
input::-webkit-input-placeholder{
  font-size: 15px;
  color: #cccccc;
}
/*Mozilla Firefox 19+*/
input::-moz-placeholder{
  font-size: 15px;
  color: #cccccc;
}
/*Mozilla Firefox 4 to 18*/
input:-moz-placeholder{
  font-size: 15px;
  color: #cccccc;
}
/*Internet Explorer 10+*/
input:-ms-input-placeholder{
  font-size: 15px;
  color: #cccccc;
}
</style>